import React from 'react';
import get from 'lodash/get';
import { StepSlider } from '../common/components';

interface Props {
  data: any;
  anchorHash?: string;
}

class ExperienceStepSliders extends React.Component<Props, any> {
  render() {
    const { data } = this.props;

    const layoutType = get(
      data,
      'primary.layout',
      'primary on the left, secondary on the right'
    );
    const items: any[] = get(data, 'items', []);
    const layout =
      layoutType === 'primary on the left, secondary on the right'
        ? 'primary-left'
        : 'primary-right';

    // primary
    const primaryTitle = get(data, 'primary.primaryTitle', '');
    const primaryDescription = get(data, 'primary.primaryDescription.html', '');
    const primaryAnchorHash = get(data, 'primary.primaryAnchorHash', '');
    // secondary
    const secondaryTitle = get(data, 'primary.secondaryTitle', '');
    const secondaryDescription = get(
      data,
      'primary.secondaryDescription.html',
      ''
    );
    const secondaryType = get(data, 'primary.secondaryType', 'card');
    const secondaryAdditional =
      secondaryType === 'card'
        ? get(data, 'primary.secondaryAdditionalDescription.html', '')
        : null;
    const secondaryAnchorHash = get(data, 'primary.secondaryAnchorHash', '');
    return (
      <StepSlider
        layout={layout}
        primary={{
          title: primaryTitle,
          description: primaryDescription,
          achorHash: primaryAnchorHash,
        }}
        secondary={{
          type: secondaryType,
          title: secondaryTitle,
          description: secondaryDescription,
          additional: secondaryAdditional,
          achorHash: secondaryAnchorHash,
        }}
        images={items}
        anchorHash={this.props.anchorHash}
      />
    );
  }
}

export default ExperienceStepSliders;
