import React, { Component } from 'react';
import get from 'lodash/get';

import { BodyImageSection } from '../common/components';
import { getInternalUrl } from '../utils';

interface Props {
  data: any;
  lang: string;
}

class ExperienceBodyImageSection extends Component<Props, any> {
  render() {
    const { data, lang } = this.props;
    const experienceTitle = get(data, 'primary.title1', '');
    const experienceDescription = get(data, 'primary.description', '');
    const experienceImage = get(data, 'primary.image');
    const linkText = get(data, 'primary.linkText', '');

    let linkUrl = get(data, 'primary.link.document[0].data.url', '');
    let linkTarget = get(data, 'primary.link.target', '');

    if (linkUrl) {
      linkUrl = getInternalUrl(get(data, 'primary.link.document[0].data.url', ''), lang);
    } else {
      linkUrl = get(data, 'primary.link.url', '');
      linkTarget = '_blank';
    }

    return (
      <BodyImageSection
        title={experienceTitle}
        description={experienceDescription}
        image={experienceImage}
        displayImagePosition="left"
        readMoreUrl={linkUrl}
        readMoreText={linkText}
        readMoreLinkTarget={linkTarget}
      />
    );
  }
}

export default ExperienceBodyImageSection;
