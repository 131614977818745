import { graphql } from 'gatsby';

import React, { Component } from 'react';
import get from 'lodash/get';

import Layout from '../layout/Layout';
import ExperienceStepSliders from './ExperienceStepSliders';
import { PageHero } from '../common/components';
import ExperienceIntroduction from './ExperienceIntroduction';
import ExperienceCourseLayout from './ExperienceCourseLayout';
import ExperienceBodyImageSection from './ExperienceBodyImageSection';

interface Props {
  data: any;
  location: any;
}

class Experience extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const experienceAnchorHash = get(this.props, 'location.hash', null);

    const body = get(data, 'page.data.body');
    const lang = get(data, 'page.lang', 'en-us');

    return (
      <Layout data={data} location={this.props.location}>
        {Array.isArray(body)
          && body.map((slice) => {
            switch (slice.__typename) {
              case 'PrismicExperienceBodyStepSlidersSection':
                return (
                  <ExperienceStepSliders
                    key={slice.id + slice.__typename}
                    data={slice}
                    anchorHash={experienceAnchorHash}
                  />
                );
              case 'PrismicExperienceBodyHero':
                return <PageHero key={slice.id + slice.__typename} data={slice} />;
              case 'PrismicExperienceBodyIntroduction1':
                return <ExperienceIntroduction key={slice.id + slice.__typename} data={slice} />;
              case 'PrismicExperienceBodyCourseLayout':
                return (
                  <ExperienceCourseLayout
                    key={slice.id + slice.__typename}
                    anchorHash={experienceAnchorHash}
                    data={slice}
                  />
                );
              case 'PrismicExperienceBodyImageSection':
                return (
                  <ExperienceBodyImageSection
                    key={slice.id + slice.__typename}
                    lang={lang}
                    data={slice}
                  />
                );

              default:
                return null;
            }
          })}
      </Layout>
    );
  }
}

export default Experience;

// Query metadata for the Experience
export const query = graphql`
  query ExperienceQuery($slug: String!) {
    page: prismicExperience(id: { eq: $slug }) {
      ...Experience
    }
  }
`;
