import React, { Component } from 'react';
import get from 'lodash/get';
import { Icon, Col, Row } from 'antd';
import styled, { css, withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Slider from 'react-slick';
import ReactSVG from 'react-svg';
import TweenOne from 'rc-tween-one';
import { theme } from '../common/theme';
import {
  SectionContainer,
  Paragraph,
  Title,
  Image,
} from '../common/components';
import scrollToComponent from 'react-scroll-to-component';
import MapLayout from './MapLayout';

const getIdOfLayoutMapByHole = activeHole => {
  return `#layout_svg__hole${activeHole + 1}`;
};
const MapLayoutWithStyle = styled(MapLayout)`
  margin: 10px 20px 10px;
  min-height:450px;
  ${breakpoint('md')`
     margin: 20px 90px 50px;
  `}
  ${breakpoint('lg')`
    margin:0;
    margin-left:20px;
  `}
  ${breakpoint('xl')`
    margin:0;
  `}
  g {
    pointer-events: fill;
    cursor: pointer;
    opacity: 0.3;
  }
  ${props =>
    props.activeSlide
      ? getIdOfLayoutMapByHole(props.activeSlide)
      : getIdOfLayoutMapByHole(0)} {
    opacity: 1;
    .layout_svg__st1 {
      fill:#32332b;
    }
  }
  g:hover {
    opacity: 1 !important;
    .layout_svg__st1 {
      fill:#32332b;
    }
  }
`;
const ReactSVGWithStyled = styled(ReactSVG)`
  .map_svg {
    g {
      pointer-events: bounding-box;
      cursor: pointer;
      opacity: 0.4;
    }
    g:hover {
      opacity: 1;
    }
  }
`;
const SlideContainer = styled.div`
  width: 100%;
  .slick-list {
    width: 100%;
    margin: 0px 50px !important;
  }
  .slick-slider {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  ul {
    list-style: none;
    display: none;
  }
`;

const CarouselArrowIcon = styled(Icon)`
  width: auto !important;
  height: auto !important;
  font-size: 30px !important;
  top: 40% !important;
  ${breakpoint('lg')`
    top:150px!important;
  `}
  z-index:5;
  color: ${props => props.theme.color.text.main}!important;
  &:hover,
  &:focus {
    color: ${props => props.theme.color.primary.main}!important;
  }

  ${props =>
    props.type &&
    (props.type === 'right' || props.type === 'left') &&
    css`
      ${props.type}: 25% !important;
      ${breakpoint('sm')`
        ${props.type}: 5px !important;
      `}
    `}
`;
const TitleHole = styled(Title)`
  color: ${props =>
    props.isActive
      ? props.theme.color.text.main
      : props.theme.color.background.main}!important;
`;

const HoleCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
`;

const LayoutContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 5rem 0;
  margin-top: 1rem;
  ${breakpoint('lg')`
    margin-top: 5 rem;
  `}
`;

const LayoutCard = styled(Row)`
  width: 100%;
  min-height: 230px;
  margin-top: 5rem;
  padding: 0 1rem;
  margin: 0 2rem;
  flex-direction: column !important;
  align-items: center;
  ${breakpoint('lg')`
    min-height: 250px;
  `}
  p {
    color: ${props =>
      props.isActive
        ? props.theme.color.text.main
        : props.theme.color.background.main}!important;
    text-align: left;
    margin-bottom: 0.5rem;
  }
  img {
    opacity: ${props => (props.isActive ? 1 : 0.1)};
  }
`;

const DescriptionCol = styled(Col)`
  padding-right: 1rem !important;
  padding-bottom: 1rem;
  margin-top: 0;
  ${breakpoint('lg')`
    margin-top: 0;
  `}
  * {
    text-align: center !important;
  }
`;

const LayoutImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 2.5rem;
  ${breakpoint('lg')`
    margin-bottom: 0rem;
  `}
  max-height:300px;
`;

const DescriptionTextContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  ${breakpoint('xs')`
    padding:0 30px;
  `}
  margin-bottom: 50px;
`;

const CarouselArrow = ({ type, ...props }) => (
  <CarouselArrowIcon
    type={type}
    onClick={props.onClick}
    className={props.className}
  />
);

const LayoutCardRow = styled(Row)`
  margin-top: 50px;
  ${breakpoint('lg')`
    margin-top:0;
  `}
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${breakpoint('md')`
    position: absolute;
    flex-direction: column;
    bottom: 70px;
    left: 50px;
  `}
  ${breakpoint('lg')`
    bottom: 50px;
    left: 10px;
  `}
`;

const LayoutImageCol = styled(Col)`
  min-height: 175px !important;
  ${breakpoint('md')`
    min-height:200px!important;
  `}
  ${breakpoint('lg')`
    min-height:250px!important;
  `}
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 10px;
  * {
    margin-bottom: 0 !important;
  }
`;

const LegendColor = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 7px;
  background: ${props => props.color};
`;

const LegendText = styled(Paragraph)`
  color: ${props => props.theme.color.text.main} !important;
`;

const DescriptionText = styled(Paragraph)`
  color: ${props => props.theme.color.text.main} !important;
  text-align: center;
`;

const LegendWrapper = ({ text, color }) => (
  <LegendItem>
    <LegendColor color={color} />
    <LegendText>{text}</LegendText>
  </LegendItem>
);

interface Props {
  data: any;
  anchorHash?: string;
}

class ExperienceCourseLayout extends Component<Props, any> {
  slider: any;
  layoutTop: any;
  titleSlider: any;
  map: any;

  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.titleSlider = React.createRef();
    this.state = {
      activeSlide: 0,
      nextActiveSlide: 0,
      moment: null,
      paused: true,
      reverse: false,
    };
  }

  Carousel = ({ items = [] }) => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoPlay: true,
      autoplaySpeed: 1000,
      pauseOnHover: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      centerMode: true,
      focusOnSelect: true,
      nextArrow: <CarouselArrow type="right" />,
      prevArrow: <CarouselArrow type="left" />,
      beforeChange: (current, next) => {
        this.setState({ nextActiveSlide: next });
        this.onReverse();
      },
      afterChange: current => {
        this.setState({ activeSlide: current });
        this.onRestart();
      },
      responsive: [
        {
          // Desktop breakpoint
          breakpoint: theme.breakpoints.xl,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          // Mobile breakpoint
          breakpoint: theme.breakpoints.sm,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <Col>
        <Slider {...settings} ref={slider => (this.titleSlider = slider)}>
          {items.map((item, idx) => {
            const title = get(item, 'title', '');
            return (
              <HoleCard key={`hole_${idx}`}>
                <TitleHole isActive={idx === this.state.activeSlide} level={3}>
                  {title}
                </TitleHole>
              </HoleCard>
            );
          })}
        </Slider>
      </Col>
    );
  };

  onRestart = () => {
    this.setState(
      {
        paused: false,
        reverse: false,
        moment: 0,
      },
      () => {
        this.setState({
          moment: null,
        });
      }
    );
  };

  onStart = () => {
    this.setState({
      paused: false,
      reverse: false,
      moment: null,
    });
  };

  onReverse = () => {
    this.setState({
      paused: false,
      reverse: true,
      moment: null,
    });
  };
  onClickMapLayout = e => {
    const target = e.currentTarget.id.split('hole');
    const hole = parseInt(target[1]) - 1;
    this.titleSlider.slickGoTo(hole);
    if (hole !== this.state.activeSlide) {
      this.onReverse();
    }
    this.setState({ activeSlide: hole });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeSlide !== this.state.activeSlide) {
      this.onStart();
    }
  }
  componentDidMount() {
    // if (this.props.anchorHash && this.props.anchorHash !== '#prices') {
    //   try {
    //     setTimeout(() => {
    //       scrollToComponent(this.layoutTop, {
    //         offset: this.props.anchorHash === '#golf-layout' ? -120 : 400,
    //         align: 'top',
    //         duration: 2000,
    //         ease: 'linear',
    //       });
    //     }, 500);
    //   } catch (e) {}
    // }
    if (this.props.anchorHash === '#golf-layout') {
      try {
        setTimeout(() => {
          scrollToComponent(this.layoutTop, {
            offset: this.props.anchorHash === '#golf-layout' ? -120 : 400,
            align: 'top',
            duration: 2000,
            ease: 'linear',
          });
        }, 500);
      } catch (e) {}
    }
    this.onStart();
  }

  render() {
    const { data } = this.props;
    const { activeSlide, paused, reverse, moment } = this.state;
    const title = get(data, 'primary.title', '');
    const items = get(data, 'items', []);
    const description = get(data, 'primary.golf_layout_description.html', '');
    const desHtml = get(items[activeSlide], 'description.html', '');
    return (
      <SectionContainer title={title} ref={p => (this.layoutTop = p)}>
        {description && (
          <DescriptionTextContainer>
            <DescriptionText>
              <span
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </DescriptionText>
          </DescriptionTextContainer>
        )}
        <Row gutter={16} align="middle" type="flex">
          <Col xs={0} md={24} lg={12}>
            <LegendContainer>
              <LegendWrapper
                text={get(data, 'primary.greenText', '')}
                color={get(data, 'primary.greenColor', 'white')}
              />
              <LegendWrapper
                text={get(data, 'primary.fairwayText', '')}
                color={get(data, 'primary.fairwayColor', 'white')}
              />
              <LegendWrapper
                text={get(data, 'primary.bunkerText', '')}
                color={get(data, 'primary.bunkerColor', 'white')}
              />
              <LegendWrapper
                text={get(data, 'primary.waterText', '')}
                color={get(data, 'primary.waterColor', 'white')}
              />
            </LegendContainer>
            <MapLayoutWithStyle
              onClick={this.onClickMapLayout}
              activeSlide={activeSlide}
            />
          </Col>
          <Col xs={24} md={24} lg={12}>
            <SlideContainer>{this.Carousel({ items })}</SlideContainer>
            <LayoutContainer>
              <LayoutCard type="flex" isActive>
                <LayoutImageCol xs={24} md={24} lg={18}>
                  {items.map((item, idx) => {
                    const img = get(item, 'layout');
                    const dimensions = get(item, 'layout.dimensions', '');
                    return <LayoutImage data={img} style={{ display: activeSlide === idx ? "block" : "none", position: "absolute" }}/>;
                  })}
                </LayoutImageCol>
                <Col xs={24} md={24} lg={24}>
                  <LayoutCardRow>
                    <DescriptionCol xs={24} md={24} lg={24}>
                      <TweenOne
                        paused={paused}
                        reverse={reverse}
                        moment={moment}
                        style={{ opacity: 0 }}
                        animation={{
                          opacity: 1,
                          duration: 500,
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: desHtml }} />
                      </TweenOne>
                    </DescriptionCol>
                  </LayoutCardRow>
                </Col>
              </LayoutCard>
            </LayoutContainer>
          </Col>
        </Row>
      </SectionContainer>
    );
  }
}

export default withTheme(ExperienceCourseLayout);
